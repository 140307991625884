import React from "react"
import { graphql, Link } from "gatsby"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import SplitCard from "../components/pages/SplitCard"
import CenterText from "../components/pages/CenterText"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

const ProductTemplate = ({ data }) => {
  var slugify = require("slugify")
  const product = data.productsYaml

  function isOdd(num) {
    return num % 2
  }

  const breadcrumbs = [
    {
      label: "Products",
      path: "/products/",
      active: false,
    },
    {
      label: product.title,
      path: product.path,
      active: true,
    },
  ]

  if (typeof window !== "undefined") {
    var win = window,
      doc = document,
      docElem = doc.documentElement,
      body = doc.getElementsByTagName("body")[0],
      x = win.innerWidth || docElem.clientWidth || body.clientWidth

    if (x >= 640) {
      var sliderOptions = "draggable: false; sets: true;"
    } else {
      var sliderOptions = "center: true;"
    }
  }

  return (
    <Layout>
      <SEO
        title={product.title}
        description={product.seo_description}
        image={product.seo_image}
      />

      <Breadcrumbs items={breadcrumbs} />

      <div className="uk-section-small uk-section-secondary">
        <div className="uk-container">
          <div className="uk-grid uk-grid-large">
            <div className="uk-width-1-2@s">
              <div className="uk-margin-large-bottom" uk-slider="">
                <div className="uk-grid uk-grid-small">
                  <div className="uk-width-1-5 uk-visible@m">
                    <div className="uk-flex uk-flex-column">
                      {product.images.map((image, i) => (
                        <div
                          key={i}
                          uk-slider-item={i}
                          className="uk-margin-small-bottom"
                        >
                          <a href="#">
                            <img
                              src={image.url}
                              alt={image.title}
                              className="uk-width-1-1"
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="uk-width-3-4 uk-width-1-1@s uk-width-4-5@m uk-margin-auto">
                    <div className="uk-position-relative">
                      <div className="uk-slider-container uk-light">
                        <div className="uk-slider-items uk-child-width-1-1">
                          {product.images.map((image) => (
                            <div key={image.url}>
                              <img
                                src={image.url}
                                alt={image.title}
                                className="uk-width-1-1"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-2@s">
              <div className="prod-desc">
                <h1 className="uk-text-large uk-text-global-family uk-text-bold">
                  {product.title}
                </h1>
                <p className="uk-text-primary uk-width-4-5 uk-width-2-3@m">
                  {product.description}
                </p>
                <div className="uk-margin">
                  <p className="uk-text-small uk-text-muted">Range</p>
                  <div className="uk-grid uk-grid-small" uk-grid="">
                    {product.variants.map((variant) => (
                      <div
                        key={variant.model}
                        className="uk-width-1-2 uk-width-1-1@s uk-width-1-2@m"
                        uk-margin=""
                      >
                        <div className="uk-card uk-card-small uk-card-xsmall uk-card-default uk-border-rounded">
                          <div className="uk-card-header uk-flex uk-flex-middle uk-flex-between">
                            <p className="uk-text-primary uk-text-p-large uk-margin-remove">
                              {variant.model}
                            </p>
                          </div>
                          {!!(
                            variant.matrix_1 ||
                            variant.matrix_2 ||
                            variant.matrix_3
                          ) ? (
                            <div className="uk-card-body">
                              <div className="uk-flex uk-flex-between uk-text-small uk-text-muted">
                                {!!variant.matrix_1 ? (
                                  <p className="uk-margin-remove uk-flex uk-flex-middle">
                                    <span
                                      className="uk-margin-xsmall-right"
                                      uk-icon="icon: cube; ratio: 0.9"
                                    ></span>{" "}
                                    {variant.matrix_1}
                                  </p>
                                ) : null}
                                {!!variant.matrix_2 ? (
                                  <p className="uk-margin-remove uk-flex uk-flex-middle">
                                    <span
                                      className="uk-margin-xsmall-right"
                                      uk-icon="icon: bolt; ratio: 0.9"
                                    ></span>{" "}
                                    {variant.matrix_2}
                                  </p>
                                ) : null}
                                {!!variant.matrix_3 ? (
                                  <p className="uk-margin-remove uk-flex uk-flex-middle">
                                    <span
                                      className="uk-margin-xsmall-right"
                                      uk-icon="icon: heating-cooling; ratio: 0.9"
                                    ></span>{" "}
                                    {variant.matrix_3}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {!!product.colours ? (
                  <div className="uk-margin-bottom">
                    <p className="uk-text-small uk-margin-small uk-text-muted">
                      Colours
                    </p>
                    <div>
                      {product.colours.map((colour) => (
                        <span
                          key={colour}
                          className={
                            "uk-label uk-margin-small-right uk-border-rounded uk-label-" +
                            slugify(colour, { lower: true })
                          }
                        >
                          {colour}
                        </span>
                      ))}
                    </div>
                  </div>
                ) : null}
                <div className="uk-margin-bottom">
                  <p className="uk-text-small uk-margin-small uk-text-muted">
                    Warranty
                  </p>
                  <div className="uk-flex warranty-cards" 
                    style={{justifyContent: product.warranties.length === 1 ? "center" : ""}}
                  >
                    {product.warranties.map((warranty) => (
                      <div
                        key={warranty.title}
                        data-divider={
                          !!product.warranty_divider
                            ? product.warranty_divider
                            : "+"
                        }
                        className="uk-card uk-card-small uk-card-xsmall uk-card-default warranty-card uk-text-center uk-width-auto uk-border-rounded"
                        style={{margin: 0}}
                      >
                        <div className="uk-card-header">
                          <h5 className="uk-text-bold uk-text-p-large uk-margin-remove">
                            {warranty.title}
                          </h5>
                        </div>
                        <div className="uk-card-body">
                          <p className="uk-margin-remove uk-text-small uk-text-muted">
                            {warranty.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  {!!product.warranty_note ? (
                    <p
                      className="uk-text-small uk-text-muted"
                      dangerouslySetInnerHTML={{
                        __html: product.warranty_note,
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Request a installer section */}
      {/* <div className="cta-bar" uk-sticky="">
        <div className="uk-background-default uk-padding-xsmall uk-padding-remove-horizontal uk-box-shadow uk-position-relative">
          <div className="uk-container">
            <div className="uk-flex uk-flex-middle uk-flex-between">
              <p className="uk-width-1-2 uk-width-auto@s uk-text-primary uk-text-bold uk-margin-remove">
                We can help you install your system.
              </p>
              <div className="uk-flex uk-flex-middle uk-visible@l uk-text-primary">
                <div className="uk-flex uk-flex-middle uk-margin-large-right">
                  <span
                    className="uk-margin-right"
                    uk-icon="icon: no.1; ratio: 1.2"
                  ></span>
                  <p className="uk-text-small uk-margin-remove">
                    Find a trusted
                    <br /> installer in your area
                  </p>
                </div>
                <div className="uk-flex uk-flex-middle uk-margin-large-right">
                  <span
                    className="uk-margin-right"
                    uk-icon="icon: no.2; ratio: 1.2"
                  ></span>
                  <p className="uk-text-small uk-margin-remove">
                    Organise an installer <br />
                    to quote for the job
                  </p>
                </div>
                <div className="uk-flex uk-flex-middle">
                  <span
                    className="uk-margin-right"
                    uk-icon="icon: no.3; ratio: 1.2"
                  ></span>
                  <p className="uk-text-small uk-margin-remove">
                    Provide market-leading <br /> support
                  </p>
                </div>
              </div>
              <div className="uk-width-3-4 uk-width-auto@s uk-flex">
                <Link
                  to="/request-an-installer/"
                  className="uk-button uk-button-primary uk-border-pill uk-margin-auto-left"
                >
                  Request an installer
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="uk-section uk-padding-remove-bottom uk-section-default">
        <div className="uk-container">
          {/* Icons */}
          <div className="uk-width-1-1 uk-width-2-3@l uk-margin-auto uk-text-center">
            <h4>{product.benefitTitle}</h4>
            <div
              className="uk-position-relative uk-visible-toggle uk-margin-medium-top"
              tabIndex="-1"
              uk-slider={sliderOptions}
            >
              <div className="uk-slider-items icon-slider">
                {product.benefits.map((benefit) => (
                  <div
                    className="uk-width-3-5 uk-width-1-2@s uk-width-1-4@m"
                    key={benefit.title}
                  >
                    <span
                      className="uk-flex uk-flex-center uk-margin-small uk-margin-bottom uk-text-primary"
                      uk-icon={
                        "icon: " +
                        benefit.icon.type +
                        "; ratio: " +
                        benefit.icon.ratio +
                        ";"
                      }
                    ></span>
                    <p className="uk-text-primary uk-text-small uk-width-4-5 uk-width-3-5@m uk-margin-auto">
                      {benefit.title}
                    </p>
                  </div>
                ))}
              </div>
              <ul className="uk-slider-nav uk-dotnav uk-margin uk-flex-center uk-hidden@m uk-width-1-1"></ul>
            </div>
          </div>
        </div>
      </div>

      <div className="uk-section uk-section-small uk-section-default uk-padding-remove-bottom">
        <div className="uk-container">
          {/* Tabs */}
          <ul
            className="uk-grid uk-child-width-expand uk-grid-match uk-tab uk-flex uk-flex-center uk-width-1-1"
            uk-grid=""
            uk-switcher="connect: #tabsContent; animation: uk-animation-fade"
          >
            {product.tabs.map((tab) => tab.title !== "Range details" && tab.title !== "Detailed specifications" && (
              <li key={tab.title}>
                <a href="#" className="uk-display-flex uk-height-1-1">
                  {tab.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <ul id="tabsContent" className="uk-switcher">
        {product.tabs.map((tab) => tab.title !== "Range details" && tab.title !== "Detailed specifications" && (
          <li key={tab.id}>
            {tab.id === "about" ? (
              <div className="aboutTab">
                <div className="uk-section uk-section-secondary">
                  <div className="uk-container">
                    {tab.features.map((feature, i) => (
                      <div key={feature.title}>
                        <SplitCard
                          title={feature.title}
                          desc={feature.body}
                          link={null}
                          btn={null}
                          image={!!feature.image && feature.image.url}
                          video={!!feature.video && feature.video.url}
                          align={isOdd(i) ? "left" : "right"}
                          style="white"
                        />
                      </div>
                    ))}
                    {/*tab.testimonialsTitle ? (
                      <CenterText
                        title={tab.testimonialsTitle}
                        data={tab.testimonial}
                        testimonial={true}
                      />
                    ) : (
                      ""
                    )*/}
                  </div>
                </div>
                <div className="uk-section uk-section-small uk-section-default">
                  <div className="uk-container">
                    <div className="uk-grid" uk-grid="">
                      <div className="uk-width-1-1 uk-width-1-4@m">
                        {tab.downloads ? (
                          <div className="uk-background-muted">
                            <h5 className="uk-padding-small uk-text-p-large uk-margin-remove uk-border-bottom-white">
                              Downloads
                            </h5>
                            <ul className="uk-padding-small uk-list uk-margin-remove">
                              {tab.downloads.map((download, i) => (
                                <li key={download.title}>
                                  <a
                                    href={download.url}
                                    target="_blank"
                                    className="uk-flex uk-flex-middle"
                                  >
                                    <span
                                      className="uk-flex-none uk-margin-small-right"
                                      uk-icon={download.icon || "file-pdf"}
                                      icon={download.icon || "file-pdf"}
                                    />
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: download.title,
                                      }}
                                    />
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="uk-width-1-1 uk-width-3-4@m">
                        <h3>{tab.aboutContent.title}</h3>
                        <p>{tab.aboutContent.body}</p>
                      </div>
                      <div className="uk-width-1-1">
                        <div className="uk-background-muted">
                          <div className="uk-container">
                            <div className="uk-grid uk-grid-match">
                              <div className="uk-width-3-5@s">
                                <div className="uk-padding">
                                  <h3>{tab.how_it_works.title}</h3>
                                  <p className="uk-text-p-large uk-margin-remove-top uk-margin-large-bottom">
                                    {tab.how_it_works.body}
                                  </p>
                                </div>
                              </div>
                              <div className="uk-width-2-5@s uk-height-small-mbl">
                                <div className="uk-cover-container uk-background-default uk-flex uk-flex-middle">
                                  <img
                                    src={tab.how_it_works.image.url}
                                    alt={tab.how_it_works.image.title}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {tab.id === "tech" ? (
              <div className="uk-section uk-section-secondary">
                <div className="uk-container">
                  {product.variants.map((variant) => (
                    <div
                      className="uk-padding uk-background-muted uk-margin-bottom"
                      key={variant.model}
                    >
                      <div
                        className="uk-flex uk-grid uk-child-width-expand"
                        uk-grid=""
                      >
                        <div className="variant-numbers">
                          <h4 className="uk-text-bold uk-margin-small">
                            Model {variant.model}
                          </h4>
                          <p className="uk-text-small uk-text-muted uk-margin-small">
                            Product Code:
                            <br />
                            {variant.product_code}
                          </p>
                        </div>
                        {!!(variant.size || variant.weight) ? (
                          <div className="variant-size">
                            {!!(
                              variant.outdoor_size || variant.outdoor_weight
                            ) ? (
                              <p className="uk-text-primary">
                                Indoor system size
                              </p>
                            ) : (
                              <p className="uk-text-primary">System size</p>
                            )}
                            <p className="uk-text-small uk-margin-small">
                              {variant.size}
                              <br />
                              {variant.weight}
                            </p>
                          </div>
                        ) : null}
                        {!!(variant.outdoor_size || variant.outdoor_weight) ? (
                          <div className="variant-size">
                            <p className="uk-text-primary">
                              Outdoor system size
                            </p>
                            <p className="uk-text-small uk-margin-small">
                              {variant.outdoor_size}
                              <br />
                              {variant.outdoor_weight}
                            </p>
                          </div>
                        ) : null}
                        {!!(variant.panel_size || variant.panel_weight) ? (
                          <div className="variant-size">
                            <p className="uk-text-primary">Panel size</p>
                            <p className="uk-text-small uk-margin-small">
                              {variant.panel_size}
                              <br />
                              {variant.panel_weight}
                            </p>
                          </div>
                        ) : null}
                        {!!variant.colours ? (
                          <div className="variant-colours">
                            <p className="uk-text-primary">Colours</p>
                            <p className="uk-text-small uk-margin-small">
                              {variant.colours.map((colour, i, arr) => (
                                <span
                                  key={i}
                                  className="uk-text-small uk-margin-small"
                                >
                                  {colour}
                                  {arr.length - 1 !== i ? ", " : null}
                                </span>
                              ))}
                            </p>
                          </div>
                        ) : null}
                        {!!variant.duct ? (
                          <div className="variant-colours">
                            <p className="uk-text-primary">
                              Drop duct connection
                            </p>
                            <p className="uk-text-small uk-margin-small">
                              {variant.duct}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}

            {tab.id === "detail" ? (
              <div className="uk-section uk-section-secondary">
                <div className="uk-container">
                  {tab.technical_tables.map((technical_table, i) => (
                    <div className="uk-margin-medium-bottom" key={i}>
                      <h4 className="uk-h5 uk-margin-small-bottom">
                        {technical_table.title}
                      </h4>
                      <div className="uk-table uk-table-hover uk-table-striped uk-table-small uk-table-middle uk-overflow-auto uk-margin-small-top uk-background-default uk-table-divider">
                        <ReactMarkdown
                          children={technical_table.url}
                          remarkPlugins={[remarkGfm]}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </li>
        ))}
      </ul>

      {/*<div className="uk-section uk-section-small">
        <div className="uk-container">
          <div className="uk-width-3-4 uk-width-2-3@s uk-width-1-2@m">
            <small className="uk-text-meta">{product.seo_description} </small>
          </div>
        </div>
      </div>*/}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    productsYaml(fields: { slug: { eq: $slug } }) {
      title
      description
      seo_description
      seo_image
      id
      images {
        url
      }
      variants {
        colours
        duct
        matrix_1
        matrix_2
        matrix_3
        model
        price
        product_code
        size
        weight
        outdoor_size
        outdoor_weight
      }
      colours
      warranties {
        title
        description
      }
      warranty_note
      warranty_divider
      benefitTitle
      benefits {
        icon {
          type
          ratio
        }
        title
      }
      tabs {
        title
        id
        features {
          title
          body
          image {
            url
            title
          }
          video {
            url
          }
        }
        testimonialsTitle
        testimonial {
          name
          desc
          link
          linkText
        }
        downloads {
          url
          title
          icon
        }
        aboutContent {
          title
          body
        }
        how_it_works {
          title
          body
          image {
            url
            title
          }
        }
        models {
          number
          desc
        }
        technical_tables {
          title
          url
        }
      }
    }
  }
`
export default ProductTemplate
